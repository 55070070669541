.contactus_container {
  // height: 700px;
  padding: 50px 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0.73)
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.73)),
    url(../assets/images/map-image.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50%;
  background-size: cover;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-weight: bold;
  }
  .contact_divider {
    width: 50px;
    height: 3px;
    background-color: white;
    margin: 0 auto 30px auto;
  }
  .text-muted {
    color: white !important;
  }
  .contact-value {
    text-decoration: none;
    color: white;
  }
  .contact-form {
    text-align: center;
    .contact-input-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      text-align: left;
      #name {
        text-transform: capitalize;
      }
      input {
        padding: 0 10px;
        border: 1px solid white;
        border-radius: 5px;
        height: 40px;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      label {
        margin-bottom: 3px;
      }
      textarea {
        padding: 10px;
        border: 1px solid white;
        border-radius: 5px;
      }
    }
    input[type="submit"] {
      background-color: transparent;
      border: 2px solid white;
      width: 169px;
      height: 40px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      text-decoration: none;
      color: white;
      margin-top: 10px;
    }
    p {
      margin-top: 15px;
    }
  }
}

@media (max-width: 767px) {
  .contact-mobile {
    padding-top: 40px;
  }
}
