.home_container {
  padding: 0px !important;
  margin-top: 64px;
  display: flex;
  flex-direction: column;

  .carousel-container {
    // height: 500px;
  }

  .home_bg {
    height: calc(100vh - 66px);
    background-image: linear-gradient(0deg,
        rgba(0, 0, 0, 0.01),
        rgba(0, 0, 0, 0.5)),
      linear-gradient(180deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.5)),
      url(../assets/images/header-bg.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 50%;
    background-size: cover;
  }
}