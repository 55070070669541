.header_container {
  padding: 0.75rem 1.4rem !important;
  background-color: #f5faff;

  .link_home {
    img {
      height: 2.5rem;
    }
  }
  .activeLink {
    font-weight: 600 !important;
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 67, 136, 0.5);
    &:focus,
    &:hover {
      color: rgba(0, 67, 136, 1);
    }
  }
  .active {
    color: rgba(0, 67, 136, 1);
  }
}
