.about_container {
  padding: 50px 0;
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0.73)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.73)),
    url(../assets/images/about-us.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50%;
  background-size: cover;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-weight: bold;
  }

  h3 {
    color: white;
  }

  .about-divider {
    width: 50px;
    height: 3px;
    background-color: white;
  }
  .about-content{
    line-height: 2.2rem;
    font-size: 1.2rem;
  }
}