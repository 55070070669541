.image-overlay {
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($color: #000000, $alpha: 0.5);
    }
}

.img-carousel {
    height: 100vh;
}

.flex-1 {
    flex: 1;
}