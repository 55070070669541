.hero-text-container {
    z-index: 9;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    h1 {
        font-size: 2.5rem;
        font-weight: bold;
    }

    h3 {
        font-size: 1.5rem;
    }

    .tell_me_btn {
        background-color: transparent;
        border: 2px solid #007bff;
        width: 169px;
        height: 40px;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        text-decoration: none;
        color: white;
        cursor: pointer;
        // padding: 0.5rem 1rem;
    }
}

.flex-2 {
    flex: 4;
}