.services_container {
  h1 {
    // color: #004388;
    font-weight: bold;
  }
  .service-divider {
    width: 50px;
    height: 3px;
    background-color: #004388;
    margin: 0 auto 40px auto;
  }
  .card {
    height: 100%;
    border: none;
    box-shadow: 1px 1px 12px rgba(183, 183, 183, 0.3);

    .card-header {
      background-color: transparent;
      border: none;
      padding-bottom: 10px;
      color: #004388;
      font-size: 16px;
      font-weight: 600;
    }
    .card-body {
      padding-top: 10px;
      font-size: 14px;
      .sub-head {
        color: #004388;
      }
    }
    .item-divider {
      background-color: #004388;
      height: 2px;
      width: 25px;
      margin: 0 1.25rem;
    }
  }
}

@media (max-width: 576px) {
  .card-group {
    margin: 0 auto;
  }
}
